'use strict'

import { reactive } from 'vue'

export class Paginator {
  constructor() {
    this.pagination = reactive({
      currentPage: 1,
      total: 0,
      perPage: 10,
      lastPage: 1,
      items: [],
      pages: [],
    })
  }

  setItems(items) {
    this.pagination.pages = this.sliceToPages(items, this.pagination.perPage)
    this.pagination.lastPage = this.pagination.pages.length
    this.pagination.total = items.length
    this.setPage(1)

    return this
  }

  setPerPage(perPage) {
    this.pagination.perPage = perPage

    return this
  }

  setPage(page) {
    if ((page > this.lastPage || page < 1) && this.lastPage !== 0) {
      return
    }

    this.pagination.currentPage = page
    this.pagination.items = this.pagination.pages.length === 0 ? [] : this.pagination.pages[page - 1]

    return this
  }

  nextPage() {
    if (this.pagination.currentPage + 1 > this.lastPage) {
      return
    }

    this.pagination.currentPage++
    this.pagination.items = this.pagination.pages[this.pagination.currentPage - 1]

    return this
  }

  previousPage() {
    if (this.pagination.currentPage - 1 < 1) {
      return
    }

    this.pagination.currentPage--
    this.pagination.items = this.pagination.pages[this.pagination.currentPage - 1]

    return this
  }

  sliceToPages(items, perPage) {
    const originalArr = JSON.parse(JSON.stringify(items))
    const splittedArray = []

    while (originalArr.length > 0) {
      splittedArray.push(originalArr.splice(0, perPage))
    }

    return splittedArray
  }

  /*
   * Getters
   */
  get items() {
    return this.pagination.items
  }

  get currentPage() {
    return this.pagination.currentPage
  }

  get perPage() {
    return this.pagination.perPage
  }

  get total() {
    return this.pagination.total
  }

  get lastPage() {
    return this.pagination.lastPage
  }

  get isCurrentLastPage() {
    return this.lastPage === this.currentPage
  }

  get isCurrentFirstPage() {
    return this.currentPage === 1
  }

  get hasPagination() {
    return this.items.length > 0
  }
}
