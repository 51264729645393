<template>
  <div
    v-if="paginator.hasPagination"
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg"
  >
    <div class="flex-1 flex space-x-3 sm:hidden">
      <button
        v-if="! paginator.isCurrentFirstPage"
        type="button"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="previous"
      >
        Previous
      </button>
      <button
        v-if="! paginator.isCurrentLastPage"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="next"
      >
        Next
      </button>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium">{{ paginator.perPage * (paginator.currentPage - 1) + 1 }}</span>
          to
          <span class="font-medium">{{ toCount }}</span>
          of
          <span class="font-medium">{{ paginator.total }}</span>
          <template v-if="paginator.total === 1">
            result
          </template>
          <template v-else>
            results
          </template>
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            type="button"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium"
            :class="{
              'cursor-not-allowed text-gray-300': paginator.isCurrentFirstPage,
              'hover:bg-gray-50 text-gray-500': ! paginator.isCurrentFirstPage,
            }"
            :disabled="paginator.isCurrentFirstPage"
            @click="previous"
          >
            <span class="sr-only">Previous</span>
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <template
            v-for="page in pages"
            :key="page"
          >
            <span
              v-if="page === 'separator'"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
            >...</span>
            <button
              v-else
              type="button"
              :class="{
                'z-10 bg-indigo-50 border-indigo-500 text-indigo-600': page === paginator.currentPage,
                'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': page !== paginator.currentPage
              }"
              class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              @click="goTo(page)"
            >
              {{ page }}
            </button>
          </template>
          <button
            type="button"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium"
            :class="{
              'cursor-not-allowed text-gray-300': paginator.isCurrentLastPage,
              'hover:bg-gray-50 text-gray-500': ! paginator.isCurrentLastPage,
            }"
            :disabled="paginator.isCurrentLastPage"
            @click="next"
          >
            <span class="sr-only">Next</span>
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { Paginator } from '@/services/paginator.js'

export default {
  name: 'Pagination',

  props: {
    paginator: {
      type: Paginator,
      required: true
    },
  },

  emits: ['next', 'previous', 'goTo'],

  setup(props, { emit }) {
    const goTo = (page) => {
      props.paginator.setPage(page)
      emit('goTo', page)
    }

    const next = () => {
      props.paginator.nextPage()
      emit('next')
    }

    const previous = () => {
      props.paginator.previousPage()
      emit('previous')
    }

    const toCount = computed(() => {
      const toCount = props.paginator.perPage * props.paginator.currentPage

      if (toCount > props.paginator.total) {
        return props.paginator.total
      }

      return toCount
    })

    const pages = computed(() => {
      let pagesToRender = []
      // Number of elements around current page
      const visiblePagesCount = 2
      // Remove 1 to keep left and right side around curren page same.
      let start = props.paginator.currentPage - visiblePagesCount - 1
      let end = props.paginator.currentPage + visiblePagesCount

      for (let i = 0; i < props.paginator.lastPage; i++) {
        pagesToRender.push(i + 1)
      }

      if (pagesToRender.length < 10) {
        return pagesToRender
      }

      // Keep number of elements in array same during all pages
      if (start < 0) {
        end -= start // start interval is minus, end interval will be increased
        start = 0
      }

      // Increase end interval to keep more elements on the right side from current page and persist number of elements
      if (start < visiblePagesCount * 2) {
        end += visiblePagesCount - start
      }

      // Increase start interval to keep more elements on the left side from current page and persist number of elements
      if (end >= props.paginator.lastPage) {
        start -= visiblePagesCount + (end - props.paginator.lastPage)
      }

      // Slice array to get only require part of pages
      pagesToRender = pagesToRender.slice(start, end)

      // if first page is not included in array, add first page to list with separator
      if (start > 1) {
          pagesToRender.unshift('separator')
          pagesToRender.unshift(1)
      }

      // If second page is active we also want to show first page but without separator
      if (start === 1) {
        pagesToRender.unshift(1)
      }

      // if last page is not included in array, add last page to list with separator
      if (end < props.paginator.lastPage) {
        pagesToRender.push('separator')
        pagesToRender.push(props.paginator.lastPage)
      }

      return pagesToRender
    })

    return {
      pages,
      goTo,
      next,
      previous,
      toCount,
    }
  }

}
</script>
