<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning
      v-if="state.hasError"
      :error="state.accessError"
    />

    <div
      v-if="!state.hasError"
      class="flex flex-col md:flex-row md:justify-between md:items-center border-b pb-2"
    >
      <h2 class="font-bold text-xl sm:text-2xl">
        {{ state.title }}
      </h2>
      <div class="w-full md:w-1/2 flex flex-row">
        <div class="w-1/2 md:justify-end flex self-center">
          <label class="mr-2">
            <input
              v-model="state.excludeSystemUsers"
              type="checkbox"
              :value="true"
              class="rounded"
            >
            <span class="ml-2">Exclude System Users</span>
          </label>
        </div>
        <text-input
          v-model="state.search"
          type="search"
          placeholder="Search..."
          class="w-1/2 sm:max-w-sm"
        />
      </div>
    </div>

    <div class="w-full pt-4">
      <export-action-buttons
        :data="exportData"
        :filename="state.title"
      />
    </div>

    <div class="bg-concrete rounded-md shadow-sm mt-6">
      <div
        v-if="!state.hasError"
        class="flex flex-col"
      >
        <v-table :is-loading="state.isLoading">
          <template #header>
            <v-table-header class="w-10" />
            <v-table-header>Email</v-table-header>
            <v-table-header>Display name</v-table-header>
            <v-table-header><span class="sr-only">Actions</span></v-table-header>
          </template>

          <v-table-row
            v-for="(user, index) in filteredUsers.items"
            :key="index"
            class=" hover:bg-gray-50"
          >
            <v-table-col>
              <span
                v-if="user.is_system_user"
                title="System User"
                class="material-icons-outlined align-middle ml-1"
              >verified_user</span>
            </v-table-col>
            <v-table-col class="font-semibold">
              <router-link
                :to="{ name: 'SettingsUsersShow', params: { id: user.email } }"
                v-html="highlightInString(user.email, state.search)"
              />
            </v-table-col>
            <v-table-col v-html="highlightInString(user.username ?? '', state.search)" />
            <v-table-col class="text-right space-x-2 flex justify-end">
              <router-link
                :title="messagesTitle(user.messages)"
                :to="{ name: 'SettingsUsersMessage', params: { id: user.email } }"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                :class="{'opacity-50': Object.keys(user.messages).length === 0}"
              >
                <span class="sr-only">message</span>
                <span class="material-icons-outlined">announcement</span>
              </router-link>
              <router-link
                :to="{ name: 'SettingsUsersShow', params: { id: user.email } }"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
              >
                <span class="sr-only">view</span>
                <span class="material-icons-outlined">visibility</span>
              </router-link>
            </v-table-col>
          </v-table-row>

          <v-table-row v-if="filteredUsers.items.length === 0">
            <v-table-col colspan="4" class="text-center">
              No Users
            </v-table-col>
          </v-table-row>

          <template #pagination>
            <pagination :paginator="filteredUsers" />
          </template>
        </v-table>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Paginator } from '@/services/paginator.js'
import http from '@/services/http.js'
import { highlightInString } from '@/helpers/utils.js'
import Layout from '@/layouts/Default.vue'
import SettingsNav from '@/components/settings/Nav'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import TextInput from '@/components/form/Text.vue'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'
import ExportActionButtons from '@/components/datatables/ActionButtons.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'SettingsUsersIndex',

  components: {
    Pagination,
    Layout,
    AlertResponseWarning,
    TextInput,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    SettingsNav,
    ExportActionButtons,
  },

  setup() {
    const route = useRoute()
    const machineId = route.params.machineId
    const searchQueryLSKey = machineId ? 'settings_machines_users__search_query' : 'settings_users_index__search_query'
    const paginator = new Paginator().setPerPage(8)
    const users = ref([])
    const state = reactive({
      title: 'Users List',
      hasError: false,
      accessError: null,
      isLoading: true,
      search: localStorage.getItem(searchQueryLSKey) || '',
      excludeSystemUsers: false,
    })

    const getUsers = async (machineId) => {
      state.isLoading = true

      try {
        const path = machineId ? `machine/${machineId}/users` : 'users'
        const { data } = await http.get(path)
        
        data.sort((a, b) => a.email.localeCompare(b.email))
        users.value = data
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }

      state.isLoading = false
    }

    const getMachineTitle = async (machineId) => {
      const title = 'Machine Users'
      state.title = title
      const { data } = await http.get(`machine/${machineId}`)
      state.title = `${data.model.name}: ${title}`
    }

    const filteredUsers = computed(() => {
      if (state.search !== '') {
        localStorage.setItem(searchQueryLSKey, state.search)
      } else {
        localStorage.removeItem(searchQueryLSKey)
      }
      const items = users.value.filter((user) => {
        return (
          (!state.excludeSystemUsers || !user.is_system_user)
          && (
            user.email.toLowerCase().includes(state.search.toLowerCase())
            || user.username?.toString().includes(state.search)
          )
        )
      })

      return paginator.setItems(items)
    })

    const structure = [
      { title: 'Email', key: 'email' },
      { title: 'Display Name', key: 'username' },
    ]
    const exportData = computed(() => {
      const result = []

      filteredUsers.value.items.forEach((items, rowIndex) => {
        structure.forEach((column, index) => {
          const data = {
            row: index,
            title: column.title,
            value: items[column.key],
          }
          result[rowIndex] ? result[rowIndex].push(data) : result.push([data])
        })
      })

      return result
    })

    const messagesTitle = (messages) => {
      const arr = Object.values(messages)
      if (arr.length === 0) return 'No Message'
      else if (arr.length === 1) return `Message: ${arr[0]}`
      else return `${arr.length} messages`
    }

    if (machineId) {
      getMachineTitle(machineId)
      getUsers(machineId)
    }
    else {
      getUsers() 
    }

    return {
      users,
      filteredUsers,
      state,
      highlightInString,
      exportData,
      messagesTitle,
    }
  }
}
</script>
